$link-color: #fff;
$link-hover-color: green;
$navbar-color: #fff;
$navbar-hover-color: green;

@import "~bootstrap/scss/bootstrap";

.modal-content {
    background-color: rgb(212, 99, 99);
}
